/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//bootstrap is disabled, deze komt via de vendor.js mee, helaas bootstrap 3
//require('./bootstrap');

window.Vue = require('vue').default;
window.axios = require('axios').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('ImageUploadComponent', require('./components/ImageUploadComponent.vue').default);
Vue.component('BlockEditorComponent', require('./components/BlockEditorComponent.vue').default);
Vue.component('KendoValueComponent', require('./components/KendoValueComponent.vue').default);
Vue.component('ButtonComponent', require('./components/ButtonComponent.vue').default);

//block-form components
Vue.component('BlockMiniHeader', require('./components/BlockMiniHeader.vue').default);
Vue.component('BlockHeader', require('./components/BlockHeader.vue').default);
Vue.component('BlockColorHeader', require('./components/BlockColorHeader.vue').default);
Vue.component('BlockImageRightTextLeft', require('./components/BlockImageRightTextLeft.vue').default);
Vue.component('BlockImageLeftAccordionRightGrayBackground', require('./components/BlockImageLeftAccordionRightGrayBackground.vue').default);
Vue.component('BlockImageLeftTextRight', require('./components/BlockImageLeftTextRight.vue').default);
Vue.component('BlockVideoLeftTextRight', require('./components/BlockVideoLeftTextRight.vue').default);
Vue.component('BlockImageRightTextLeftGrayBackground', require('./components/BlockImageRightTextLeftGrayBackground.vue').default);

Vue.component('BlockPopupImageLeftTextRight', require('./components/BlockPopupImageLeftTextRight.vue').default);

Vue.component('BlockTestimonials', require('./components/BlockTestimonials.vue').default);
Vue.component('BlockPakketSelectie', require('./components/BlockPakketSelectie.vue').default);
Vue.component('BlockContactFormulier', require('./components/BlockContactFormulier.vue').default);
Vue.component('BlockOpgaveFormulier', require('./components/BlockOpgaveFormulier.vue').default);
Vue.component('BlockButtonTags', require('./components/BlockButtonTags.vue').default);
Vue.component('BlockUSPList', require('./components/BlockUSPList.vue').default);
Vue.component('BlockTwoIconBlocks', require('./components/BlockTwoIconBlocks.vue').default);
Vue.component('BlockFullBackgroundWithButtons', require('./components/BlockFullBackgroundWithButtons.vue').default);
Vue.component('BlockSoftConversion', require('./components/BlockSoftConversion.vue').default);

Vue.component('BlockKennisbank', require('./components/BlockKennisbank.vue').default);
Vue.component('BlockPakkettenKoppelen', require('./components/BlockPakkettenKoppelen.vue').default);
Vue.component('BlockCustomHTML', require('./components/BlockCustomHTML.vue').default);
Vue.component('pagelist', require('./components/PageList.vue').default);

Vue.component('abtest', require('./components/abtest.vue').default);
Vue.component('widgets', require('./components/widgets.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    methods: {
      onBlockEditorChange: function(data)
      {
        window.blockEditorData = data;
      }
    }
});

window.loadAjaxVue = function()
{
  let ajaxApp = new Vue({
      el: '#ajax-app',
  });
}

require('../assets/js/app.js');