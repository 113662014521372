<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="meta.anchor" v-on:change="notifyChanges" />
        </div>

        <div class="col-xs-6">
            <div class="row">
                <div class="col-xs-12">
                    Achtergrond kleur:<br />
                    Kies: <select v-bind:value="colorSelect" v-on:change="onChangeColorSelect($event)">
                        <option value="custom">custom</option>
                        <option value="orange">Oranje</option>
                        <option value="blue">Blauw</option>
                        <option value="bg-1">Thema 1</option>
                        <option value="bg-2">Thema 2</option>
                    </select><br>
                    
                    <chrome-picker v-if="colorSelect == 'custom'" v-model="meta.bgcolor" acceptLabel="ok"></chrome-picker>
                </div>
                <div class="col-xs-12">
                    <KendoValueComponent v-model="meta.title.value" :tools="tools"/>
                    <div class="form-group" style="clear:both;">
                        <label>Titel stijl</label>
                        <select class="form-control" v-model="meta.title.font">
                            <option value="">-geen stijl-</option>
                            <option value="schreef">schreef</option>
                            <option value="schreefloos">schreefloos</option>
                        </select>
                    </div>
                </div>
                    
                <div class="col-xs-12">
                    <KendoValueComponent v-model="meta.text" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <ButtonComponent v-model="meta.mainButton" />
                </div>
                <div class="col-xs-12">
                    <ButtonComponent v-model="meta.extraButton" />
                </div>
            </div>
        </div>
        <div class="col-xs-6">
            <ImageUploadComponent label="Afbeelding rechts" v-model="meta.mainImage" containerStyle="float:left;border:1px solid green;" />
            
            <div class="form-group" style="clear:both;">
                <label>Afbeelding stijl</label>
                <select class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="meta.mainImageSettings.valign">
                    <option value="">-geen stijl-</option>
                    <option value="top">bovenaan positioneren</option>
                    <option value="bottom">onderaan positioneren</option>
                    <option value="center">verticaal centreren</option>
                </select>
            </div>
            <!--<div class="form-group" style="clear:both;">
                <label>Maximale afbeelding breedte</label>
                <input class="form-control inlined" @change="notifyChanges" @input="notifyChanges" v-model="meta.mainImageSettings.maxwidth" />
            </div>-->
            
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    /* https://github.com/xiaokaike/vue-color */
    var Picker = require('vue-color/src/components/Chrome.vue').default;
     
    export default {
        components: {
            'ChromePicker': Picker,
        },
        props: ['data'],
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                meta: this.data,
                editColor: false
            };

            if (obj.meta.bgcolor == "")
            {
                obj.meta.bgcolor = 'bg-1';
            }

            return obj;
        },
        computed: {
            colorSelect: function()
            {
                if (typeof this.meta.bgcolor === 'string' || this.meta.bgcolor instanceof String)
                {
                    return this.meta.bgcolor;
                }
                return 'custom';
            }
        },
        watch: {
            meta: {
              handler(newValue, oldValue) {
                this.$emit('change', newValue);
              },
              deep: true
            }
        },
        methods: {
            notifyChanges()
            {
                this.$emit('change', this.meta);
                
            },
            onChangeColorSelect: function(event)
            {
                if (event.target.value == 'custom')
                {
                    this.meta.bgcolor = { r: 255, g: 0, b: 0 };
                }
                else
                {
                    this.meta.bgcolor = event.target.value;
                }
            }
        }
    }
</script>
