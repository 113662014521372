<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>
        <div class="col-xs-6">
            <div class="row">
                <div class="col-xs-12">
                    <KendoValueComponent v-model="titel" label="Titel" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <KendoValueComponent v-model="subtitel" label="Subtitel" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <KendoValueComponent v-model="tekst" label="Tekst" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <ButtonComponent v-model="mainButton" />
                </div>
                <div class="col-xs-12">
                    <ButtonComponent v-model="extraButton" />
                </div>
            </div>
        </div>

        <div class="col-xs-6">
            <div>
                <label>Afbeelding fade</label>
                <select v-model="imageFadeClass">
                    <option value="">Uitschakelen</option>
                    <option value="fade-gradient-1">Standaard</option>
                    <option value="fade-gradient-2">Alleen onder</option>
                </select>
            </div>
            <ImageUploadComponent label="Afbeelding rechts" v-model="mainImage" containerStyle="float:left;border:1px solid green;" />
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>
