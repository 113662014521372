<template>
<div class="container-fluid custom-html-block">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="blockData.anchor" />
        </div>
        <div class="col-xs-12">
            <h3>Uitleg</h3>
            <p>Indien bij Head/Body-scripts een naam is ingevuld, wordt dit item eenmalig per naam per pagina getoond.</p>
            <p>Bij overige blokken kunnen (alleen) scripts geplaatst worden in de rich-text-editor, plak deze dan als tekst.</p>
        </div>
        <div class="col-xs-12">
            <h3>HTML</h3>
            <textarea v-model="blockData.html"></textarea>
        </div>

        <div class="col-xs-6">
            <h3>Head-scripts <a class="btn btn-xs btn-info" v-on:click.prevent="onCreateHeadScript">+</a></h3>
            <ol>
                <li v-for="(headScript, headScriptIndex) in blockData.headScripts">
                    <a class="btn btn-xs btn-danger delete" v-on:click.prevent="onDeleteHeadScriptIndex(headScriptIndex)">verwijder</a>
                    <label>Naam</label> <input type="text" v-model="headScript.name"><br />
                    Code: <br />
                    <textarea v-model="headScript.script"></textarea>
                </li>
            </ol>
        </div>
        <div class="col-xs-6">
            <h3>Body-scripts <a class="btn btn-xs btn-info" v-on:click.prevent="onCreateBodyScriptBottom">+</a></h3>
            <ol>
                <li v-for="(bodyScript, bodyScriptIndex) in blockData.bodyScriptsBottom">
                    <a class="btn btn-xs btn-danger delete" v-on:click.prevent="onDeleteBodyScriptBottomIndex(bodyScriptIndex)">verwijder</a>
                    Naam: <input type="text" v-model="bodyScript.name"><br />
                    Code: <br />
                    <textarea v-model="bodyScript.script"></textarea>
                </li>
            </ol>
        </div>
    </div>
</div>
</template>
<style>
    .custom-html-block textarea {
        width: 100%;
        min-height: 200px;
    }
    
    .custom-html-block .delete {
        float: right;
        display: none;
    }
    
    .custom-html-block ol {
        padding-left: 20px;
    }
    
    .custom-html-block li:hover .delete {
        display: inline;
    }
    
</style>
<script type="application/javascript">
    export default {
        props: ['data'],
        data()
        {
            let tools = ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"];
            
            return {
                blockData: {
                    anchor: this.data.anchor,
                    html: this.data.html,
                    headScripts: JSON.parse(JSON.stringify(this.data.headScripts)),
                    bodyScriptsBottom: JSON.parse(JSON.stringify(this.data.bodyScriptsBottom))
                },
                
            };
        },
        watch:
        {
            blockData: {
                handler: function(newValue, oldValue)
                {
                    this.$emit('change', JSON.parse(JSON.stringify(newValue)));
                },
                deep: true
            }
        },
        methods: {
            onCreateHeadScript: function()
            {
                this.blockData.headScripts.push({
                    name: '',
                    script: ''
                });
            },
            onCreateBodyScriptBottom: function()
            {
                this.blockData.bodyScriptsBottom.push({
                    name: '',
                    script: ''
                });
            },
            onDeleteBodyScriptBottomIndex: function(index)
            {
                if (confirm('Weet je zeker dat je dit Body-script wilt verwijderen?'))
                {
                    this.blockData.bodyScriptsBottom.splice(index, 1);
                }
            },
            onDeleteHeadScriptIndex: function(index)
            {
                if (confirm('Weet je zeker dat je dit Head-script wilt verwijderen?'))
                {
                    this.blockData.headScripts.splice(index, 1);
                }
            }
        }
        
    }
</script>
